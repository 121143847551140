@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Roboto Mono", monospace;
  font-size: 22px;
  color: #fff;
}

body {
  min-height: 100vh;
  background-color: #242424;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 0.5rem;
}

section {
  width: 100%;
  max-width: 500px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

form {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

a,
a:visited {
  color: #ffd700;
}

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: "Roboto Mono", monospace;
  font-size: 18px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
  background-color: #ffd700; /* Yellow button */
  color: #242424; /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

button:hover {
  background-color: #e0ac00; /* Darker yellow on hover */
}

.instructions {
  font-size: 0.65rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: #ff6347;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

/* Styling for authorized state */
.authorized {
  padding: 20px;
  background-color: #242424;
  color: #fff;
  position: fixed;
  top: 0;
  left: 100px; /* Adjust according to the width of the sidebar */
  width: calc(100% - 200px); /* Adjust to fill the remaining width */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authorized h3 {
  margin-bottom: 10px;
}

.logout {
  background-color: transparent;
  color: #ffd700;
  border: none;
  cursor: pointer;
}

/* Styling for sidebar */
.sidebar {
  width: 200px;
  background-color: #1a1a1a; /* Darker background color */
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 100px; /* Adjust according to the height of the authorized element */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
}

.sidebar ul li a {
  color: #ffd700;
  text-decoration: none;
}

/* Styling for unauthenticated state */
.unauthenticated {
  padding: 20px;
  text-align: center;
}

.unauthenticated h3 {
  margin-bottom: 10px;
}

.unauthenticated a {
  color: #ffd700;
  text-decoration: none;
}

.form-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
/* Styling for company form */
.company-form {
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 20px;
  margin-top: 20px;
  width: auto;
}

.company-form h2 {
  margin-bottom: 20px;
}

.company-form label {
  margin-top: 2px;
}

.company-form input[type="text"],
.company-form textarea {
  margin-top: 5px;
}

.company-form button {
  margin-top: 20px;
}

/* CSS for CompanyCard component */
.company-card {
  background-color: #1a1a1a;
  color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

/* CSS for company list */
.company-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  /* Add some top margin to create space between the list and other elements */
}

/* CSS for individual company card */
.company {
  margin: 10px; /* Add margin to create space between individual company cards */
}

/* Optional: Adjust the width of individual company cards if needed */
.company-card {
  width: 400px;
  height: 200px; /* Adjust width as needed */
}
.company-link {
  text-decoration: none;
}
.role-input {
  height: 40px; /* Adjust the width as needed */
}

/* Add styles for the CompanyPage */
.company-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Center the form */
.user-registration-form {
  width: 50%; /* Adjust the width as needed */
  margin-top: 20px; /* Add margin from the top */
  padding: 20px; /* Add padding to the form */
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: auto;
}

/* Style for form heading */
.user-registration-form h2 {
  margin-bottom: 20px;
  color: #ffd700; /* Yellow color for heading */
}

/* Style for form inputs */
.user-registration-form label {
  margin-top: 10px;
  color: #fff; /* White color for labels */
}

.user-registration-form input[type="text"],
.user-registration-form input[type="password"] {
  margin-top: 5px;
  padding: 10px; /* Add padding to inputs */
  border-radius: 5px;
  border: 1px solid #ffd700; /* Yellow border */
  background-color: #242424; /* Dark background */
  color: #fff; /* White color for text */
}

/* Style for form button */
.user-registration-form button {
  margin-top: 20px;
  padding: 10px 20px; /* Add padding to button */
  background-color: #ffd700; /* Yellow button */
  color: #242424; /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-registration-form button:hover {
  background-color: #e0ac00; /* Darker yellow on hover */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .user-registration-form {
    width: 80%; /* Adjust width for smaller screens */
  }
}

.company-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: auto;
}

/* Add this to your existing CSS */
.user-registration-form input[type="text"],
.user-registration-form input[type="password"],
.user-registration-form select {
  padding: 12px; /* Increase padding for larger input boxes */
  font-size: 16px; /* Increase font size for larger input boxes */
  border-radius: 5px;
  border: 1px solid #ffd700; /* Yellow border */
  background-color: #242424; /* Dark background */
  color: #fff; /* White color for text */
}

.user-registration-form input[type="email"],
.user-registration-form input[type="companyID"] {
  padding: 10px; /* Increase padding for larger input box */
  font-size: 20px; /* Increase font size for larger input box */
}
/* Updated CSS for UserTable component */

.user-table-container {
  margin-top: 20px;
}

.user-table {
  width: 120%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.user-table th {
  background-color: #242424;
  color: #ffd700;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

.button-container button {
  margin: 0 10px; /* Adjust spacing between buttons */
}
